export default {
  pageText: "{0} ru",
  noDataText: "Нет данных",
  lang: {
    ru: "Русский",
    en: "English",
    es: "Español",
  },
  addPlaylist: {
    name: "Название",
    urlAddress: "URL адрес .M3U8 или подключение к VPortal",
    forDevices: "Доступен на устройствах",
    save: "Сохранить",
    incorrectUrl: "Некорректный URL",
    onlyM3UAllowed: "Доступна загрузка только M3U и M3U8 плейлистов",
    selectAll: "Выбрать все",
    playlistAllowOnAll: "Плейлист будет доступен на всех ваших устройствах",
    playlistAllowOnSelected: "Плейлист будет доступен на выбранных устройствах",
    selectOneOrMore: "Выберите одно или несколько устройств",
    allowOnDevices: "На выбранных устройствах будет доступен плейлист"
  },
  drawer: {
    player: "Плеер",
    addPlaylist: "Добавить плейлист",
    playlists: "Плейлисты",
    vportals: "VPortals",
    devices: "Устройства",
    profiles: "Профили",
    exit: "Выход"
  },
  devices: {
    deleteButton: "Удалить",
    deleteDialog: {
      title: "Удаление",
      wantDelete: "Вы хотите удалить устройство: {0}",
      cancel: "Отмена",
      delete: "Удалить"
    }
  },
  loginForm: {
    title: "Авторизация",
    emailField: "Email",
    passwordField: "Пароль",
    loginButton: "Войти",
    registrationButton: "Регистрация",
  },
  playlist: {
    channels: "{0} каналов",
    allowedOn: "Доступен на {0} устройствах",
    details: "Подробнее",
    edit: "Изменить",
    deleteDialog: {
      title: "Удалить",
      wantDelete: "Вы хотите удалить плейлист: {0}",
      cancel: "Отмена",
      delete: "Удалить"
    },
    editForm: {
      title: "Редактирование плейлиста",
      nameField: "Название",
      saveButton: "Сохранить",
      cancelButton: "Отмена",
      failMessage: "При сохранении возникла ошибка"
    },
  },
  vportal: {
    key: "Key: {0}",
    address: "URL: {0}",
    allowedOn: "Доступен на {0} устройствах",
    details: "Подробнее",
    edit: "Изменить",
    deleteDialog: {
      title: "Удалить",
      wantDelete: "Вы хотите удалить VPortal: {0}",
      cancel: "Отмена",
      delete: "Удалить"
    },
    editForm: {
      title: "Редактирование VPortal",
      nameField: "Название",
      saveButton: "Сохранить",
      cancelButton: "Отмена",
      failMessage: "При сохранении возникла ошибка"
    },
  },
  profile: {
    saveSystemDialogSave: "Обновить системные",
    saveDialog: "Список доступных каналов изменен. Не забудьте сохранить изменения.",
    saveDialogSave: "Сохранить",
    saveDialogCancel: "Отмена",
    currentPlaylist: "Плейлист",
    groupFilter: "Фильтр по группам",
    currentPage: "Страница {0} из {1}",
    channels: "Каналы",
    deleteDialog: {
      title: "Удаление",
      wantDelete: "Вы хотите удалить профиль: {0}",
      cancel: "Отмена",
      delete: "Удалить"
    },
    editForm: {
      title: "Редактирование профиля",
      nameField: "Название",
      saveButton: "Сохранить",
      restoreButton: "Восстановить",
      deleteButton: "Удалить",
      cancelButton: "Отмена",
      pinFieldHint: "Пинкод профиля",
      pinFieldDesc: "Введите 4 цифры или оставьте поле пустым",
    },
  },
  registrationForm: {
    title: "Регистрация",
    emailField: "Email",
    nickField: "Псевдоним",
    loginButton: "Войти",
    registrationButton: "Регистрация",
  },
  detail: {
    privacyPolicy: "Политика конфиденциальности",
    terms: "Условия использования",
  },
  formMessages: {
    isRequired: "Поле должно быть заполнено",
    mustBeLess: "Поле должно содержать не более {0} символов",
    onlyDigits: "Поле может содержать только цифры",
    mustBeEqual: "Поле должно содержать ровно {0} символа",
    incorrectEmail: "Введите корректный email",
  },
  emptyView: {
    titleDevice: "Список устройств пуст",
    descriptionDevice: "Добавление нового устройства в этот список происходит автоматически при установке приложения на Ваше устройство для воспроизведения видео. После первой успешной авторизации на устройстве под Вашей учетной записью.",
    titleSource: "Список плейлистов пуст",
    descriptionSource: "Работа с приложением предполагает то, что у Вас уже есть ссылка на m3u или m3u8 плейлист. Добавьте ссылку на имеющийся у Вас плейлист.",
    addPlaylist: "Перейти к добавлению ссылки на плейлист",
    titleVportal: "Список порталов пуст",
    descriptionVportal: "В скором времени приложения на всех платформах будут поддерживать работу с VPortal серверами. Если у вас уже есть реквизиты доступа к порталу, вы можете добавить их, чтобы получить доступ к фильмам и передачам. На данный момент доступ к VPortal реализован только на устройствах Apple.",
    addVportal: "Перейти к добавлению VPortal",
    routeButtonAdd: "Добавить",
    downloadDescription: "Приложение доступно на устройствах Google или Apple",
    titleProfile: "Список профилей пока недоступен",
    descriptionProfile: "Работа с приложением предполагает то, что у Вас уже есть ссылка на m3u или m3u8 плейлист. Добавьте ссылку на имеющийся у Вас плейлист.",

  },
  externalView: {
    title: "Где взять плейлист?",
    description: "Если у вас еще нет плейлиста для просмотра, могу порекомендовать замечательный одноименный сервис. Зарегистрируйтесь, получите ссылку на плейлист с тысячами ТВ каналов и наслаждайтесь отличным качеством видео и звука.",
    secondDescription: "Для добавления имеющегося у вас доступа в VPortal, введите строку подключения к порталу в поле адреса. Строка подключения имеет вид: portal::[key:1234567890abcde-1234567890abcde]http://server.com/api/v1/",
    addition: "Приятного просмотра!",
    buttonLabel: "За плейлистами",
  },
  playerView: {
    title: "iLook ott player",
    description: "iLook ott player - простой и удобный плеер для просмотра M3U8 плейлистов доступен на нескольких популярных платформах. Вы легко можете найти приложение в магазинах приложений App Store и Google Play. Совсем недавно заработала веб версия приложения.",
    description_important: "Важно!",
    description_attansion: "Приложение не содержит телевизионных каналов и служит исключительно для воспроизведения плейлистов, которые у вас уже есть.",
    labelToSite: "Смотреть на сайте"
  },
  successMessages: {
    successLogin: "Добро пожаловать!",
    successRegistration: "Ваши регистрационные данные отправлены на указанный email",
    playlistAdded: "Плейлист успешно добавлен",
    playlistSaved: "Изменения прейлиста сохранены",
    playlistDeleted: "Плейлист удален",
    vportalAdded: "VPortal успешно добавлен",
    vportalSaved: "Изменения сохранены",
    vportalDeleted: "VPortal удален",
    deviceUpdated: "Информация об устройстве обновлена",
    deviceDeleted: "Устройство удалено",
    profileAdded: "Профиль успешно добавлен",
    profileRestored: "Профиль восстановлен до исходного состояния",
    profilesSaved: "Изменения в профилях сохранены",
    profileSaved: "Профиль сохранен"
  },
  failMessages: {
    failProfileAdd: "Ошибка при добавлении профиля",
    failProfileUpdate: "Ошибка при обновлении профиля",
    failPlaylistUpdate: "Ошибка при обновлении плейлиста",
  }
};
