export default {
  state: {
    isShowing: true,
    isSmallScreen: true,
    scrollOffset: 0,
  },
  getters: {},
  mutations: {
    toggleDrawer(state) {
      state.isShowing = !state.isShowing;
    },
    showDrawer(state) {
      state.isShowing = true;
    },
    hideDrawer(state) {
      state.isShowing = false;
    },
  },
  actions: {},
};
