<template>
  <v-container>Error</v-container>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      text: "123",
    };
  },
};
</script>