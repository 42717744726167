import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "../locales/ru.ts";
import es from "../locales/es.ts";
import en from "../locales/en.ts";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, es, ru },
    current: "es",
  },
  theme: {
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#f3f3f3",
      },
      dark: {
        primary: "#4CAF50",
      },
    },
  },
});
