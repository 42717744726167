<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      text: "123",
    };
  },
};
</script>