import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Meta from "vue-meta";
import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);
Vue.use(Meta);

Vue.config.productionTip = false;

export const eventEmitter = new Vue();

router.beforeEach((to, from, next) => {
  console.log('localStorage.token :>> ', localStorage.token);
  console.log("new route :>> ", from, to, (localStorage.token != null && localStorage.token != "null"));
  if (to.meta && to.meta.isPersonal && (localStorage.token != null && localStorage.token != "null")) {
    next();
    return;
  } else if (to.meta && to.meta.isPersonal && !(localStorage.token != null && localStorage.token != "null")) {
    next({ name: "login" });
    return;
  }
  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  });
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
