<template>
  <v-container> 
      <div>
    <v-alert
      text
      color="info"
      type="warning"
    >
      <h3 class="headline">
        {{title}}
      </h3>
      <div>{{description}}</div>

      <v-divider
        class="my-4 info"
        style="opacity: 0.22"
      ></v-divider>

      <v-row
      v-if="isShowAppLink"
        align="center"
        no-gutters
      >
        <v-col class="grow">
          {{this.$vuetify.lang.translator('emptyView.downloadDescription')}}
        </v-col>
        <v-spacer></v-spacer>
        <v-row class="shrink">
            <v-btn
                  href="https://play.google.com/store/apps/details?id=com.overinet.ilook"
                  target="_blank"
                  class="ma-2"
                  color="info"
                  icon
                >
                  <v-icon>mdi-google-play</v-icon>
                  
            </v-btn>
            <v-btn
                  href="https://apps.apple.com/ru/developer/stanislav-belsky/id1116371927"
                  target="_blank"
                  class="ma-2 mr-4"
                  color="info"
                  icon
                >
                  <v-icon>mdi-apple</v-icon>
                  
            </v-btn>
        </v-row>
            
      </v-row >
      <v-row
        v-else
        align="center"
        no-gutters
      >
        <v-col class="grow">
          {{subtitle}}
        </v-col>
        <v-spacer></v-spacer>
        <v-row class="shrink">
            <v-btn
                  :to="{ name: `add_source`}"
                  class="ma-2 mr-4"
                  color="info"
                  outlined
                >
                  {{this.$vuetify.lang.translator('emptyView.routeButtonAdd')}}
                  
            </v-btn>
        </v-row>
            
      </v-row>
    </v-alert>
  </div>
  </v-container>
</template>

<script>

export default {
    name: "AddSource",
    props: {
        title: String,
        description: String,
        subtitle: String,
        isShowAppLink: Boolean,
    },
    components: {
  },
  data() {
    return {
    };
  },
};
</script>