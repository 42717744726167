<template>
  <v-app>
    <app-bar></app-bar>

    <router-view></router-view>

    <v-snackbar
      :color="$store.getters.getOptions.color"
      v-model="$store.state.snackbar.isShowing"
      :multi-line="false"
      >{{ $store.getters.getOptions.text }}</v-snackbar
    >
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";

export default {
  name: "App",

  components: {
    appBar: AppBar,
  },

  data() {
    return {
      text: "123",
    };
  },
  created() {
    this.$store.dispatch("getVersionInfo");
    this.updateLocaleFromCookie();
    this.$store.state.drawer.isShowing = window.innerWidth >= 700;
    this.$store.state.drawer.isSmallScreen = window.innerWidth <= 700;
  },
  mounted() {
    
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.$nextTick(() => {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {},
  methods: {
    onScroll() {
      // console.log("object", window.scrollY);
      this.$store.state.drawer.scrollOffset = window.scrollY;
    },
    onResize() {
      console.log("onResize");
      this.$store.state.drawer.isSmallScreen = window.innerWidth <= 700;
    },
    updateLocaleFromCookie() {
      let browserLang = navigator.language || navigator.userLanguage;
      let langFromCookie = this.$cookie.get("locale");
      if (
        langFromCookie &&
        this.$store.getters.getLocales.includes(langFromCookie)
      ) {
        this.$vuetify.lang.current = langFromCookie;
      } else if (
        browserLang &&
        Array.isArray(browserLang.split("-")) &&
        this.$vuetify.lang.current != browserLang.split("-")[0] &&
        this.$store.getters.getLocales.includes(browserLang.split("-")[0])
      ) {
        this.$vuetify.lang.current = browserLang.split("-")[0];
      }
    },
  },
};
</script>
