export default {
  state: {
    isShowing: false,

    options: {
      text: "Hello, I'm a snackbar",
      color: "success",
    },
  },
  getters: {
    getOptions(state) {
      return state.options;
    },
  },
  mutations: {
    showSnackbar(state, payload) {
      state.options = payload;
      state.isShowing = true;
    },
    hideSnackbar(state) {
      state.isShowing = false;
    },
  },
  actions: {},
};
