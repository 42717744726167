<template>
  <v-container v-if="isEmpty">
    <empty-view 
    :title="this.$vuetify.lang.translator('emptyView.titleDevice')" 
    :description="this.$vuetify.lang.translator('emptyView.descriptionDevice')" 
    :isShowAppLink="true">

    </empty-view>
  </v-container>
  <v-container v-else @click="restore">
    <v-col v-for="(item, i) in $store.state.device.devices" :key="i">
      <v-card class="mx-auto" max-width="344" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline mb-4">
              {{ $store.state.device.devices[i].brand }}
            </div>
            <v-list-item-title class="">
              <v-text-field
                ref="textField"
                :placeholder="$store.state.device.devices[i].name"
                required
                v-model="$store.state.device.devices[i].name"
                :rules="[
                  () =>
                    !!$store.state.device.devices[i].name ||
                    'This field is required',
                ]"
                label="Regular"
                single-line
                :append-outer-icon="icon(i)"
                counter
                maxlength="20"
                dense
                @focus="focusField(i)"
                @click:append-outer="save"
                @click:append="restore"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-subtitle
              >id:
              {{
                $store.state.device.devices[i].manufacturerId
              }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-avatar tile size="60" color="grey"
            ><v-icon dark large>
              {{ getDeviceAvatar($store.state.device.devices[i].os) }}
            </v-icon></v-list-item-avatar
          >
        </v-list-item>

        <v-card-actions>
          <v-btn
            outlined
            rounded
            text
            @click="deleteDevice($store.state.device.devices[i])"
          >
            {{$vuetify.lang.translator('devices.deleteButton')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <device-delete-dialog
      v-if="$store.state.device.isShowingDeleteDialog"
    ></device-delete-dialog>
  </v-container>
</template>

<script>
import DeviceDeleteDialog from "./DeviceDeleteDialog";
import EmptyView from "@/components/EmptyView";

export default {
  name: "Devices",
  components: {
    deviceDeleteDialog: DeviceDeleteDialog,
    emptyView: EmptyView
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isEmpty(){
      return !this.$store.state.device.devices || this.$store.state.device.devices.length == 0
    }
  },
  methods: {
    focusField(i) {
      console.log("focus");
      this.restore();
      this.$store.state.device.editingDevice = {
        ...this.$store.state.device.devices[i],
      };
    },
    icon(i) {
      if (
        this.$store.getters.isEditable(this.$store.state.device.devices[i].id)
      ) {
        return "mdi-pencil";
      } else {
        return null;
      }
    },
    restore() {
      console.log("restore");
      this.$nextTick(() => {
        if (
          this.$refs.textField.filter((item) => {
            return item.isFocused;
          }).length == 0
        ) {
          this.$store.commit("clear");
        }
      });
      this.$store.commit("restore");
    },
    save() {
      console.log("save");
      this.$store.dispatch("save");
    },
    getDeviceAvatar(os) {
      if (os == "ios" || os == "tvos") {
        return "mdi-apple";
      } else {
        return "mdi-android";
      }
    },
    showDevices() {
      console.log("devices :>> ", this.$store.state.device.devices);
    },
    deleteDevice(device) {
      this.$store.commit("setDeletingDevice", device);
    },
  },

  created() {
    this.$store.dispatch("getDevices");
  },
};
</script>