<template>
  <v-app-bar app color="primary" dark>
    <v-row align="center" justify="center">
      <v-col lg="1" md="2" xl="1" sm="2" fluid align="left" justify="center">
        <v-app-bar-nav-icon
          v-if="this.$store.state.drawer.isSmallScreen"
          @click.stop="$store.commit('toggleDrawer')"
        ></v-app-bar-nav-icon>
      </v-col>
      <v-col class="d-flex">
        <v-spacer></v-spacer>
        <v-img
          alt="iLook ott player"
          class="shrink mr-2"
          contain
          src="../assets/tv_logo.png"
          transition="scale-transition"
          width="50"
        />
        <v-img
          alt="iLook ott player"
          class="shrink mr-2"
          contain
          src="../assets/logo_mini_white.png"
          transition="scale-transition"
          width="90"
        />
        <v-spacer></v-spacer>
      </v-col>
      <v-col lg="1" md="2" xl="1" sm="2" fluid align="center" justify="center">
        <v-select
          :hint="`${select.state}, ${select.abbr}`"
          v-model="select"
          :items="items"
          item-text="state"
          item-value="abbr"
          menu-props="auto"
          hide-details
          single-line
        ></v-select>
      </v-col>
    </v-row>
  </v-app-bar>
</template>



<script>
import { eventEmitter } from "../main";

export default {
  components: {},
  data() {
    return {
      select: this.getCurrentOption(),
      items: this.getLangOptions(),
      meta: { flush: true },
    };
  },
  watch: {
    select() {
      console.log("select :>> ", this.select);
      this.updateLocale(this.select);
    },
  },
  computed: {},
  methods: {
    getCurrentOption() {
      return {
        state: this.$vuetify.lang.translator(
          `lang.${this.$vuetify.lang.current}`
        ),
        abbr: this.$vuetify.lang.current,
      };
    },
    getLangOptions() {
      return this.$store.getters.getLocales.map((item) => {
        return {
          state: this.$vuetify.lang.translator(`lang.${item}`),
          abbr: item,
        };
      });
    },
    updateLocale(newLocale) {
      this.$vuetify.lang.current = newLocale;
      this.items = this.getLangOptions();
      eventEmitter.$emit("updateLocale");
      this.$cookie.set("locale", this.$vuetify.lang.current, {
        expires: "1Y",
      });
    },
  },
};
</script>

