<template>
  <v-container>
      <!-- <h3 class="headline">
        {{this.$vuetify.lang.translator('playerView.title')}}
      </h3> -->
      <v-row
      class="shrink ma-6"
        align="center"
        no-gutters
      >
      <v-col class="grow" ></v-col>
        <v-col class="grow" cols="6" >
            <v-img
                alt="iLook ott player"
                class="shrink mr-2"
                contain
                src="../assets/ilook_player_logo.png"
                transition="scale-transition"
                />
            </v-col>
            <v-col class="grow" ></v-col>
        </v-row>
        <v-divider
        class="my-2 success"
        style="opacity: 0"
      ></v-divider>
      <div>{{this.$vuetify.lang.translator('playerView.description')}}</div>
      <br/>
      <div> <b>{{this.$vuetify.lang.translator('playerView.description_important')}}</b> {{this.$vuetify.lang.translator('playerView.description_attansion')}} </div>
      <v-divider
        class="my-2 success"
        style="opacity: 0"
      ></v-divider>

      <v-row
        align="center"
        no-gutters
      >
        <v-col class="grow">
          
          <a href="https://player.ilook.su" target="_blank">
            {{this.$vuetify.lang.translator('playerView.labelToSite')}}
                    </a>
        </v-col>
        <v-spacer></v-spacer>
        <v-row class="shrink">
            <v-col cols="auto">
                    <a href="https://play.google.com/store/apps/details?id=com.overinet.ilook"  target="_blank">
                        <v-img 
                        width="100"
                        to="/afiliacion"
                        src="@/assets/google_play_light.svg"
                        />
                    </a>
                    </v-col>
                    
                    <v-col cols="auto">
                    <a href="https://apps.apple.com/us/app/ilook-ott-player/id1547163047" target="_blank">
                        <v-img
                        width="100"
                        src="@/assets/app_store_light.svg"
                    />
                    </a>
                    </v-col>
        </v-row>
            
      </v-row >
  </v-container>
</template>

<script>

export default {
  name: "PlayerView",
  components: {
    
  },
  data() {
    return {
    
    };
  },
  computed: {
   
  },

  methods: {
  }
};
</script>
