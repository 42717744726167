export default {
    state: {
        playlistIdForFilter: null,
        currentChannelsList: [],
    },
    getters: {},
    mutations: {

        setPlaylistForService(state, payload) {
      
            state.playlistIdForFilter = payload.id;
            state.currentChannelsList = payload.items;

          },
    },
    actions: {
        
        loadDetailPlaylistForService(context, playlistId) {
            let payload = {
              id: playlistId,
              completion: (playlist) => {
                if (playlist) {
                  console.log("playlist :>> ", playlist);
                  context.commit("setPlaylistForService", playlist);
                }
              },
            };
            context.dispatch("getPlaylistInfo", payload);
          },
    },
  };
  