<template>
  <v-container>
    <v-col class="pa-2 ma-0" >
        
        <v-row class="pa-2 ma-0" style="width: 100%">
        <v-select
          :hint="`${$store.state.service.playlistIdForFilter.name}, ${$store.state.service.playlistIdForFilter.id}`"
          v-model="$store.state.service.playlistIdForFilter"
          :items="$store.state.playlist.list"
          item-text="name"
          item-value="id"
          menu-props="auto"
          hide-details
          single-line
          :label="$vuetify.lang.translator('profile.currentPlaylist')"
          
        ></v-select>
        </v-row>
        <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
            <th class="text-left">
            <b>Group</b>
          </th>
          <th class="text-left">
            <b>Full name</b>
          </th>
          <th class="text-left">
            <b>Name</b>
          </th>
          <th class="text-left">
            <b>Synname</b>
          </th>
          <th class="text-left">
            <b>Image</b>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(channel, n) in $store.state.service.currentChannelsList"
            :key="n"
        >
        <td>{{ channel.group }}</td>
          <td>{{ channel.originalName }}</td>
          <td>{{ channel.name }}</td>
          <td>{{ channel.synName }}</td>
          <td>
              <a 
              target="_blank"
              :href="`https://file.ilook.su/public/iLook/img/${channel.synName}.png`">
                <v-img
                max-height="72"
                max-width="128"
                :src="`https://file.ilook.su/public/iLook/img/${channel.synName}.png`"
                ></v-img>
                </a>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        

    </v-col>
  </v-container>
</template>

<script>

export default {
  name: "Service",
  components: {},
  data() {
    return {
      dialog: false,
    };
  },
  watch:{
    currentPlayListId(playlistId){
      this.$store.dispatch("loadDetailPlaylistForService", playlistId);
    }
  },
  computed: {
      currentPlayListId(){
      return this.$store.state.service.playlistIdForFilter
    },
  },
  methods: {
      
  },
    created() {

    let payload = {
        completion: (shortPlaylists) => {
          if (shortPlaylists.length > 0) {
            console.log("shortPlaylists :>> ", shortPlaylists);
            this.$store.dispatch("loadDetailPlaylistForService", shortPlaylists[0].id);
          }
        },
      };

    this.$store.dispatch("getPlaylists", payload);
},
};
</script>