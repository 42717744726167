<template>
    <v-dialog
      v-model="$store.state.profile.isShowingDeleteProfileDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline"> {{$vuetify.lang.translator('profile.deleteDialog.title')}} </v-card-title>
        <v-card-text
          >{{$vuetify.lang.translator('profile.deleteDialog.wantDelete', $store.state.profile.deletingProfile.name)}}
          </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancel"> {{$vuetify.lang.translator('profile.deleteDialog.cancel')}} </v-btn>
          <v-btn color="green darken-1" text @click="action"> {{$vuetify.lang.translator('profile.deleteDialog.delete')}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  // name: "DeleteProfileDialog",
  //   props: {
  //     device: {
  //       type: Object,
  //       required: true,
  //     },
  //   },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    cancel() {
      this.$store.commit("setProfileForDelete", null);
    },
    action() {
      this.$store.dispatch("deleteProfile");
      this.$store.commit("setProfileForDelete", null);
    },
  },
  destroyed() {
    this.$store.commit("setProfileForDelete", null);
  },
};
</script>