import axios from "axios";
import apiUtil from "../util/api_util";
import { drop, dropRight, filter, union } from "lodash"
import Vuetify from "../plugins/vuetify";

export default {
  state: {
    saveAlert: false,
    allChecker: {},
    playlistIdForFilter: null,
    currentChannelsList: [],
    filterGroups: [],
    currentGroupsList: [],
    table: [],
    profiles: {},
    elementPerPage: 20,
    currentPage: null,
    isShowingCreateProfileDialog: false,
    creatingProfile: null,
    isShowingProfileEditDialog: false,
    editingProfile: null,
    isShowingDeleteProfileDialog: false,
    deletingProfile: null,
  },
  getters: {
    filteredTable(state) {
      let filteredChannelNames = state.currentChannelsList.filter((channel) => {
        return state.filterGroups.length == 0 || channel.group == null || state.filterGroups.includes(channel.group)
      }).map((channel) => {
        return channel.name
      })

      return filter(state.table, function (o) { return filteredChannelNames.includes(Object.keys(o)[0]); })

    },
    page(state, getters) {
      console.log("------>", getters.filteredTable.length, state.filterGroups);

      let dropLeftCount = (state.currentPage - 1) * state.elementPerPage
      let dropRightCount = getters.filteredTable.length - state.elementPerPage * state.currentPage
      return dropRight(drop(getters.filteredTable, dropLeftCount), dropRightCount)
    },
    maxPageCount(state, getters) {
      let row = getters.filteredTable.length / state.elementPerPage
      if (Number.isInteger(row)) {
        return row
      } else {
        return parseInt(row, 10) + 1
      }
    },
    getChannelsNamesObject(state) {

      console.log('current :>> ', state.editingProfile.id);

      let result = {
        allowed: [],
        blocked: []
      }

      state.table.forEach((element) => {
        let channelName = Object.keys(element)[0]
        let synNames = state.currentChannelsList.filter((item) => item.name == channelName).map((el) => el.synName)
        let profileDataObj = Object.values(element)[0]
        Object.keys(profileDataObj).forEach((profileId) => {
          if (profileId === state.editingProfile.id) {
            if (profileDataObj[profileId]) {
              result.allowed = union(result.allowed, synNames)
              // result.allowed.push(synName)
            } else {
              result.blocked = union(result.blocked, synNames)
              // result.blocked.push(synName)
            }
          }

        })
      })

      return result
    },
    getObjectFromTable(state) {
      let result = {}

      state.profiles.forEach(profile => {
        result[profile.id] = {
          allowed: [],
          blocked: []
        }
      })

      state.table.forEach((element) => {

        let channelName = Object.keys(element)[0]
        let synNames = state.currentChannelsList.filter((item) => item.name == channelName).map((el) => el.synName)
        // console.log('synNames :>> ', synNames);
        let profileDataObj = Object.values(element)[0]
        Object.keys(profileDataObj).forEach((profileId) => {
          if (profileDataObj[profileId]) {
            result[profileId].allowed = union(result[profileId].allowed, synNames)
            // result[profileId].allowed.push(synName)
          } else {
            result[profileId].blocked = union(result[profileId].blocked, synNames)
            // result[profileId].blocked.push(synName)
          }

        })
      })


      return result
    }
  },
  mutations: {
    // setGroupFilter(state, payload){
    //   state.currentGroup = payload
    // },
    setProfileForCreate(state, payload) {
      console.log("profile :>> ", payload);
      state.isShowingCreateProfileDialog = payload != null;
      state.creatingProfile = payload
    },
    setProfileForDelete(state, payload) {
      console.log("profile :>> ", payload);
      state.isShowingDeleteProfileDialog = payload != null;
      state.deletingProfile = payload
    },
    setProfileForEdit(state, payload) {
      console.log("profile :>> ", payload);
      state.isShowingProfileEditDialog = payload != null;
      state.editingProfile = payload
    },
    showNextPage(state) {
      state.currentPage += 1
    },
    showPrevPage(state) {
      state.currentPage -= 1

    },
    showPage(state, payload) {
      state.currentPage = payload
    },
    setCurrentPlaylist(state, payload) {

      state.playlistIdForFilter = payload.id;
      state.currentChannelsList = payload.items;
      state.currentGroupsList = []
      state.filterGroups = []
      payload.items.forEach((item) => {
        if (item.group && !state.currentGroupsList.includes(item.group)) {
          state.currentGroupsList.push(item.group)
        }
      })

      this.commit("createTable");
    },
    createTable(state) {
      console.log("createTable");
      state.saveAlert = false
      state.table = [];
      let synNames = [];
      for (let channel of state.currentChannelsList) {

        if (synNames.includes(channel.synName)) {
          continue
        }

        synNames.push(channel.synName)

        let resultByProfiles = {};
        state.profiles.forEach((profile) => {
          resultByProfiles[profile.id] = !profile.blockedNames.includes(
            channel.synName
          );
        });
        let tableObj = {};
        tableObj[channel.name] = resultByProfiles;

        let isAlreadyPresent = false
        for (let element of state.table) {
          if (Object.keys(element)[0] === channel.name) {
            isAlreadyPresent = true
            continue
          }
        }

        if (!isAlreadyPresent) {
          state.table.push(tableObj);
        }


      }
      state.currentPage = state.table.length > 0 ? 1 : null
      console.log(state.table);
    },
  },
  actions: {
    checkUncheckAll(context, payload) {
      // console.log('checkUncheckAll :>> ', payload, context.getters.filteredTable.length);
      context.getters.filteredTable.forEach((item) => {
        // console.log('item :>> ', Object.values(item)[0][payload.profileName]);
        Object.values(item)[0][payload.profileId] = payload.isCheked
      })
    },
    loadDetailPlaylist(context, playlistId) {
      let payload = {
        id: playlistId,
        completion: (playlist) => {
          if (playlist) {
            console.log("playlist :>> ", playlist);
            context.commit("setCurrentPlaylist", playlist);
          }
        },
      };
      context.dispatch("getPlaylistInfo", payload);
    },
    loadPlaylistWithCompletion(context) {
      let payload = {
        completion: (shortPlaylists) => {
          if (shortPlaylists.length > 0) {
            console.log("shortPlaylists :>> ", shortPlaylists);
            context.dispatch("loadDetailPlaylist", shortPlaylists[0].id);
          }
        },
      };
      context.dispatch("getPlaylists", payload);
    },
    reloadProfiles(context) {
      console.log("reloadProfiles");
      let payload = {
        completion: () => {
          context.commit("createTable");
        },
      };
      context.dispatch("getProfiles", payload)
    },
    getProfiles(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/profiles/`,
          {
            headers,
          }
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          // console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object && Array.isArray(data.object)) {
            console.log("object :>> ", data.object);
            context.state.profiles = data.object;
            context.state.profiles.forEach((profile) => {
              context.state.allChecker[profile.id] = false
            })

            if (payload && payload.completion) {
              payload.completion();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    deleteProfile(context) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = { headers };

      axios
        .delete(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/profiles/${context.state.deletingProfile.id}`,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data.object && data.object) {
            console.log("OK");
            context.dispatch("reloadProfiles")
            return;
          }
          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });

    },
    restoreProfile(context) {
      console.log("restore profile");

      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = { headers };

      axios
        .delete(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/profiles/${context.state.editingProfile.id}`,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data.object) {
            console.log("OK");

            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.profileRestored"
              ),
              color: "success",
            });

            context.dispatch("reloadProfiles")
            return;
          }
          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });


    },
    updateSingleProfile(context) {

      console.log("update profile", context.state.editingProfile);

      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = {
        headers,
      };

      axios
        .patch(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/profiles/${context.state.editingProfile.id}`,
          { ...context.state.editingProfile, ...context.getters.getChannelsNamesObject },
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.profileSaved"
              ),
              color: "success",
            });

            context.dispatch("reloadProfiles")
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });

    },
    updateSytemsProfilesData(context) {

      let result = context.getters.getObjectFromTable

      console.log("update system profile", result);

      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = {
        headers,
      };

      axios
        .put(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/profiles/`,
          result,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.profilesSaved"
              ),
              color: "success",
            });
            context.dispatch("reloadProfiles")
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });

    },
    updateProfilesData(context) {

      let result = context.getters.getObjectFromTable

      console.log("update profile", result);

      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = {
        headers,
      };

      axios
        .patch(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/profiles/`,
          result,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.profilesSaved"
              ),
              color: "success",
            });
            context.dispatch("reloadProfiles")
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });

    },
    createProfile(context) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);
      console.log("payload :>> ", this.creatingProfile);

      let config = {
        headers,
      };

      axios
        .post(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/profiles/`,
          context.state.creatingProfile,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.profileAdded"
              ),
              color: "success",
            });

            context.dispatch("reloadProfiles")
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
  },
};
