import axios from "axios";
import apiUtil from "../util/api_util";
import { pick } from "lodash";
import Vuetify from "../plugins/vuetify";

export default {
  state: {
    isShowingEditDialog: false,
    editingPlaylist: null,
    isShowingDeleteDialog: false,
    deletingPlaylist: null,
    list: [],
  },
  getters: {
    getPlaylists(state) {
      return state.list;
    },
    getPlaylistName(state) {
      if (state.deletingPlaylist != null) {
        return state.deletingPlaylist.name;
      } else {
        return "";
      }
    },
  },
  mutations: {
    setPlaylistForEdit(state, payload) {
      console.log("playlist :>> ", payload);
      state.isShowingEditDialog = payload != null;

      let playlist = { ...payload };
      if (payload != null)
        playlist.devices = playlist.devices.map((item) => {
          return item.id;
        });
      state.editingPlaylist = playlist;
    },
    setPlaylistForDelete(state, payload) {
      console.log("playlist :>> ", payload);
      state.isShowingDeleteDialog = payload != null;
      state.deletingPlaylist = payload;
    },
  },
  actions: {
    deletePlaylist(context) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = { headers };

      axios
        .delete(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/playlist/${context.state.deletingPlaylist.id}`,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data.object) {
            console.log("OK");
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.playlistDeleted"
              ),
              color: "success",
            });
            context.dispatch("getPlaylists");
            return;
          }
          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });

    },
    getPlaylists(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/playlist/`,
          {
            headers,
          }
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            context.state.list = data.object.map((item) => {
              item.show = true;
              item.deviceDescription = item.devices
                .map((device) => {
                  return device.name;
                })
                .join(", ");
              return item;
            });
            if (payload && payload.completion) {
              payload.completion(data.object);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    updatePlaylist(context) {
      console.log(
        "context.state.editingPlaylist :>> ",
        context.state.editingPlaylist
      );
      let headers = apiUtil.getTokenHeaders("application/json");
      let playlist = pick(context.state.editingPlaylist, ["name", "devices"]);
      console.log("headers :>> ", headers);
      console.log("playlist :>> ", playlist);

      let config = {
        headers,
      };

      axios
        .put(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/playlist/${context.state.editingPlaylist.id}`,
          playlist,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.playlistSaved"
              ),
              color: "success",
            });
            context.dispatch("getPlaylists");
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: Vuetify.framework.lang.translator(
              "playlist.editForm.failMessage"
            ),
            color: "error",
          });
        });
    },
    getPlaylistInfo(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/playlist/${payload.id}`,
          {
            headers,
          }
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            if (payload && payload.completion) {
              payload.completion(data.object);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    createPlaylist(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");
      let playlist = pick(payload, ["name", "address", "devices"]);

      console.log("headers :>> ", headers);
      console.log("payload :>> ", payload);
      console.log("playlist :>> ", playlist);

      let config = {
        headers,
      };

      axios
        .post(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/playlist/`,
          playlist,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.playlistAdded"
              ),
              color: "success",
            });
            if (payload && payload.competion) {
              payload.competion(true);
            }

          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
  },
};
