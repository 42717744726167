<template>
  <v-container v-if="isEmpty">
    <empty-view 
    :title="this.$vuetify.lang.translator('emptyView.titleProfile')" 
    :description="this.$vuetify.lang.translator('emptyView.descriptionProfile')" 
    :isShowAppLink="false">

    </empty-view>
  </v-container>
  <v-container v-else>
    <v-alert
      :value="$store.state.profile.saveAlert"
      type="info"
      text
      dense
      icon="mdi-content-save-edit-outline"
      prominent
      transition="scale-transition"
    >
      <v-row align="center">
        <v-col class="grow" >
          {{$vuetify.lang.translator('profile.saveDialog')}}
        </v-col>
        <v-col class="shrink" align="center" justify="center">
          <v-btn v-if="this.isAdmin" color="info"
            outlined rounded @click="trySaveSystems()">{{$vuetify.lang.translator('profile.saveSystemDialogSave')}}</v-btn>
          <v-btn color="info"
            outlined rounded @click="trySave()">{{$vuetify.lang.translator('profile.saveDialogSave')}}</v-btn>
          <v-btn color="info" class="mt-2"
            outlined rounded text @click="tryReload()">{{$vuetify.lang.translator('profile.saveDialogCancel')}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-sheet 
    v-if="$store.state.profile.playlistIdForFilter != null"
    id="scrolling_sheet" class="overflow-y-auto">
    
      <v-row class="pa-2 ma-0" :style="getRowSheetStyle">
        
        <v-row class="pa-2 ma-0" style="width: 100%">
        <v-select
          :hint="`${$store.state.profile.playlistIdForFilter.name}, ${$store.state.profile.playlistIdForFilter.id}`"
          v-model="$store.state.profile.playlistIdForFilter"
          :items="$store.state.playlist.list"
          item-text="name"
          item-value="id"
          menu-props="auto"
          hide-details
          single-line
          :label="$vuetify.lang.translator('profile.currentPlaylist')"
          
        ></v-select>
        </v-row>
        <v-row v-if="$store.state.profile.currentGroupsList.length > 0" class="pa-2 ma-0" style="width: 100%">
        <v-select
          v-model="$store.state.profile.filterGroups"
          :items="$store.state.profile.currentGroupsList"
          :label="$vuetify.lang.translator('profile.groupFilter')"
          multiple
          clearable
        ></v-select>
        </v-row>
        <v-row 
          style="width: 100%"
          justify="center"
          no-gutters>
          
          <v-col cols="12" fluid align="center" justify="center" class="mb-3">
            <v-btn
              text
              :disabled="$store.state.profile.currentPage == 1"
              @click="prevPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            {{$vuetify.lang.translator('profile.currentPage', $store.state.profile.currentPage, $store.getters.maxPageCount)}}        
            <v-btn
              text
              :disabled="$store.state.profile.currentPage == $store.getters.maxPageCount"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pa-2 ma-0" style="width: 100%">
          <v-col class="pa-0" cols="3"> {{$vuetify.lang.translator('profile.channels')}} </v-col>
          <v-col
            class="pa-0"
            v-for="(profile, n) in $store.state.profile.profiles"
            :key="n"
            align="center"
            justify="center"
            link
          >
          <a @click="showDialogAboutProfile(profile)">{{ profile.name }} </a>
              
          </v-col>
        </v-row>
        <v-row class="pa-2 ma-0" style="width: 100%">
          <v-col class="pa-0" cols="3"> </v-col>
          <v-col
            class="pa-0"
            v-for="(profile, n) in $store.state.profile.profiles"
            :key="n"
            align="center"
            justify="center"
          >
            <v-checkbox
              :id="`checkbox_${profile.id}`"
              style="margin:0px; margin-left:8px;"
              class="pa-0  shrink"
              color="red"
              v-model="$store.state.profile.allChecker[profile.id]"
              @change="check($event, profile)"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row
          class="pa-2 ma-0"
          style="width: 100%"
          v-for="(item, i) in $store.getters.page"
          :key="i"
        >
          <v-col class="pa-0" cols="3">
            {{ Object.keys(item)[0] }}
          </v-col>
          <v-col
            class="pa-0 text-center"
            v-for="(profile, n) in $store.state.profile.profiles"
            :key="n"
            align="center"
            justify="center"
          >
            <v-checkbox
              style="margin:0px; margin-left:8px;"
              class="pa-0 shrink"
              v-model="Object.values(item)[0][profile.id]"
              hide-details
              @change="clickOnSomeChecker($event, profile)"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row 
          class="mb-3 mt-2"
          style="width: 100%"
          justify="center"
          no-gutters>
          
          <v-col cols="12" fluid >
            <div class="text-center">
            <v-pagination
              v-model="$store.state.profile.currentPage"
              :length="$store.getters.maxPageCount"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
        <v-row class="pa-2 ma-0" style="width: 100%" justify="center">
          
          <v-btn outlined rounded text @click="createProfile()"> Create </v-btn>
        </v-row>
      </v-row>
      
    </v-sheet>
    <profile-edit-dialog
      v-if="$store.state.profile.isShowingProfileEditDialog"
    ></profile-edit-dialog>
    <profile-delete-dialog
      v-if="$store.state.profile.isShowingDeleteProfileDialog"
    ></profile-delete-dialog>
    <profile-create-dialog
      v-if="$store.state.profile.isShowingCreateProfileDialog"
    ></profile-create-dialog>
  </v-container>
</template>

<script>
import ProfileEditDialog from "./ProfileEditDialog"
import ProfileDeleteDialog from "./ProfileDeleteDialog"
import ProfileCreateDialog from "./ProfileCreateDialog"
import EmptyView from "@/components/EmptyView";

export default {
  name: "ProfilesList",
  components: {
    profileEditDialog: ProfileEditDialog,
    profileDeleteDialog: ProfileDeleteDialog,
    profileCreateDialog: ProfileCreateDialog,
    emptyView: EmptyView
  },
  data() {
    return {
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isEmpty(){
      return !this.$store.state.playlist.list || this.$store.state.playlist.list.length == 0
    },
    getRowSheetStyle(){
      let width = 300 + this.$store.state.profile.profiles.length * 70
      console.log('width :>> ', width);
      return `width: ${width}px`
    },
    currentPlayListId(){
      return this.$store.state.profile.playlistIdForFilter
    },
    filterGroups(){
      return this.$store.state.profile.filterGroups
    }
  },
  watch:{
    filterGroups(groupNames){
      console.log('groupName :>> ', groupNames);
      this.setPage(1)
    },
    currentPlayListId(playlistId){
      this.$store.dispatch("loadDetailPlaylist", playlistId);
    }
  },
  methods: {
    showDialogAboutProfile({...profile}){
      this.$store.commit("setProfileForEdit", profile)
    },
    tryReload(){ 
      this.$store.dispatch("loadDetailPlaylist", this.currentPlayListId);
    },
    trySave(){
      this.$store.dispatch("updateProfilesData");
      this.$store.state.profile.saveAlert = false
    },
    trySaveSystems(){
      this.$store.dispatch("updateSytemsProfilesData");
      this.$store.state.profile.saveAlert = false
    },
    clickOnSomeChecker(event, profile){
      this.$store.state.profile.saveAlert = true
      this.$store.state.profile.allChecker[profile.id] = false
    },
    check(event, profile){
      console.log('check :>> ', event, profile);
      this.$store.state.profile.saveAlert = true
      this.$store.dispatch('checkUncheckAll', {
        profileId: profile.id,
        isCheked: event
      })
    },
    setPage(number){
      this.$store.commit('showPage', number)
    },
    prevPage(){
      this.$store.commit('showPrevPage')
    },
    nextPage(){
      this.$store.commit('showNextPage')
    },
    loadPlaylinsAndSetCurrent() {
      let payload = {
        completion: () => {
          this.$store.dispatch("loadPlaylistWithCompletion");
        },
      };
      this.$store.dispatch("getProfiles", payload);
    },
    createProfile() {
      let profile = {
        name: "",
        pinCode: null,
        blockedNames: [],
      }
      this.$store.commit("setProfileForCreate", profile)
      // this.$store.dispatch("createProfile");
    },
  },

  created() {
    // this.$store.dispatch("loadPlaylinsAndSetCurrent");
    this.loadPlaylinsAndSetCurrent()
  },
};
</script>

<style scoped>
.v-input {
  margin-left: 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: inline-block;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
}


</style>