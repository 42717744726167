<template>
  <v-dialog
    max-width="500px"
    v-model="$store.state.profile.isShowingCreateProfileDialog"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            this.$vuetify.lang.translator("profile.editForm.title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :counter="20"
                  :rules="nameRules"
                  :label="
                    this.$vuetify.lang.translator('profile.editForm.nameField')
                  "
                  required
                  v-model="$store.state.profile.creatingProfile.name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="$store.state.profile.creatingProfile.pinCode"
                  :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="pinRules"
                  :type="showPin ? 'text' : 'password'"
                  name="input-10-1"
                  :label="this.$vuetify.lang.translator('profile.editForm.pinFieldHint')"
                  :hint="this.$vuetify.lang.translator('profile.editForm.pinFieldDesc')"
                  counter
                  @click:append="showPin = !showPin"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">{{
            this.$vuetify.lang.translator("profile.editForm.cancelButton")
          }}</v-btn>
          <v-btn
            :disabled="!isValidForm"
            color="blue darken-1"
            text
            @click="saveProfileIfCan()"
            >{{
              this.$vuetify.lang.translator("profile.editForm.saveButton")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      showPin: false,
      lazy: false,
      valid: false,
      pinRules: [
        (v) =>
          (/.[0-9]+/.test(v) || (!v)) ||
          this.$vuetify.lang.translator("formMessages.onlyDigits"),
        (v) =>
          ((v && v.length == 4) || (v && v.length == 0) || !v) ||
          this.$vuetify.lang.translator("formMessages.mustBeEqual", 4),
        
      ],
      nameRules: [
        (v) => !!v || this.$vuetify.lang.translator("formMessages.isRequired"),
        (v) =>
          (v && v.length <= 30) ||
          this.$vuetify.lang.translator("formMessages.mustBeLess", 20),
      ],
    };
  },
  computed: {
    isValidForm() {
      return this.valid;
    },
  },
  methods: {
    cancel() {
      this.$store.commit("setProfileForCreate", null);
    },
    saveProfileIfCan() {
      this.$refs.form.validate();
      if (!this.isValidForm) {
        return;
      }
      this.$store.dispatch("createProfile");
      this.$store.commit("setProfileForCreate", null);
    },

  },
  watch: {},
  destroyed() {
    this.$store.commit("setProfileForCreate", null);
  },
};
</script>


<style scoped>
</style>
