<template>
  <v-container :locale="$vuetify.lang.current" class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{
              this.$vuetify.lang.translator("loginForm.title")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-text-field
                name="email"
                prepend-icon="mdi-email"
                type="text"
                :rules="emailRules"
                :label="this.$vuetify.lang.translator('loginForm.emailField')"
                required
                v-model="email"
              ></v-text-field>

              <v-text-field
                id="password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :rules="passwordRules"
                :label="
                  this.$vuetify.lang.translator('loginForm.passwordField')
                "
                required
                v-model="password"
              ></v-text-field>
              <v-row justify="end" class="me-0">
                <vue-recaptcha
                  ref="recaptcha"
                  :loadRecaptchaScript="true"
                  :sitekey="recaptchaKey"
                  @verify="register"
                  @expired="onCaptchaExpired"
                />
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small color="primary" :to="{ path: `/registration` }">{{
              this.$vuetify.lang.translator("loginForm.registrationButton")
            }}</v-btn>
            <v-btn
              :disabled="!isValidForm"
              @click="tryLogin()"
              color="primary"
              >{{
                this.$vuetify.lang.translator("loginForm.loginButton")
              }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Login",
  components: { VueRecaptcha },
  data() {
    return {
      lazy: false,
      valid: false,
      recaptchaToken: null,
      email: "",
      password: "",
      passwordRules: [
        (v) => !!v || this.$vuetify.lang.translator("formMessages.isRequired"),
        (v) =>
          (v && v.length <= 30) ||
          this.$vuetify.lang.translator("formMessages.mustBeLess", 30),
      ],
      emailRules: [
        (v) => !!v || this.$vuetify.lang.translator("formMessages.isRequired"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$vuetify.lang.translator("formMessages.incorrectEmail"),
      ],
    };
  },
  computed: {
    recaptchaKey() {
      return this.$store.getters.getRecaptchaKey;
    },
    isValidForm() {
      console.log("this.recaptchaToken :>> ", this.recaptchaToken);
      return this.valid && this.recaptchaToken != null;
    },
  },
  methods: {
    register(recaptchaToken) {
      console.log("recaptchaToken :>> ", recaptchaToken);
      this.recaptchaToken = recaptchaToken;
    },

    validate() {
      this.$refs.recaptcha.execute();
    },

    onCaptchaExpired() {
      this.recaptchaToken = null;
      this.$refs.recaptcha.reset();
    },
    tryLogin() {
      this.$refs.form.validate();
      if (!this.isValidForm) {
        return;
      }

      this.$store.dispatch("tryLogin", {
        email: this.email,
        password: this.password,
        lang: this.$vuetify.lang.current,
        recaptchaToken: this.recaptchaToken,
      });
      this.onCaptchaExpired();
    },
  },
  mounted() {
    // this.validate();
  },
};
</script>