const hashUtil = require("./hash_util");

// export class ContentType {

//   constructor(){
//     var JSON = "application/json"
//     TEXT = "text/html"
//     MULTIPART = "multipart/mixed"
//     FORM = "application/x-www-form-urlencoded"
//     STREAM = "application/octet-stream"
//     BINARY = "application/x-binary"
//   }

// }

class ApiUtil {
  getSecHeaders(password, lang, type) {
    let headers = {};
    headers.email = localStorage.email;
    headers.time = Math.round(+new Date() / 1000);
    headers.app = "iLook";
    headers.os = "www-site";
    headers.hash = hashUtil.getHash(headers, password);
    headers["Content-type"] = type;
    headers.lang = lang;

    return headers;
  }

  getTokenHeaders(type, lang) {
    let headers = {};
    headers.time = Math.round(+new Date() / 1000);
    headers.app = "iLook";
    headers.os = "www-site";
    headers.token = localStorage.token;
    headers["Content-type"] = type;
    headers.lang = lang || "en";

    return headers;
  }
}

module.exports = new ApiUtil();
