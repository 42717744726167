<template>
  <v-row justify="center">
    <v-dialog
      v-model="$store.state.vportal.isShowingDeleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline"> {{$vuetify.lang.translator('vportal.deleteDialog.title')}} </v-card-title>
        <v-card-text
          >
          {{$vuetify.lang.translator('vportal.deleteDialog.wantDelete', $store.getters.getVportalName)}}
          </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancel"> {{$vuetify.lang.translator('vportal.deleteDialog.cancel')}} </v-btn>
          <v-btn color="green darken-1" text @click="action"> {{$vuetify.lang.translator('vportal.deleteDialog.delete')}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "VportalDeleteDialog",
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$store.commit("setVportalForDelete", null);
    },
    action() {
      this.$store.dispatch("deleteVportal");
      this.$store.commit("setVportalForDelete", null);
    },
  },
  destroyed() {
    this.$store.commit("setVportalForDelete", null);
  },
};
</script>