<template>
  <v-container> </v-container>
</template>

<script>
export default {
  name: "Redirect",
  data: () => ({}),
  created() {
    this.$store.commit("setToken", { token: this.$route.query.token });
  },
};
</script>