import axios from "axios";
import apiUtil from "../util/api_util";
import { pick } from "lodash";
import Vuetify from "../plugins/vuetify";

export default {
  state: {
    isShowingEditDialog: false,
    editingVportal: null,
    isShowingDeleteDialog: false,
    deletingVportal: null,
    list: [],
  },
  getters: {
    getVportals(state) {
      return state.list;
    },
    getVportalName(state) {
      if (state.deletingVportal != null) {
        return state.deletingVportal.name;
      } else {
        return "";
      }
    },
  },
  mutations: {
    setVportalForEdit(state, payload) {
      console.log("vportal :>> ", payload);
      state.isShowingEditDialog = payload != null;

      let vportal = { ...payload };
      if (payload != null)
          vportal.devices = vportal.devices.map((item) => {
          return item.id;
        });
      state.editingVportal = vportal;
    },
    setVportalForDelete(state, payload) {
      console.log("vportal :>> ", payload);
      state.isShowingDeleteDialog = payload != null;
      state.deletingVportal = payload;
    },
  },
  actions: {
    deleteVportal(context) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = { headers };

      axios
        .delete(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/vportal/${context.state.deletingVportal.id}`,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data.object) {
            console.log("OK");
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.vportalDeleted"
              ),
              color: "success",
            });
            context.dispatch("getVportals");
            return;
          }
          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });

    },
    getVportals(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/vportal/`,
          {
            headers,
          }
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            context.state.list = data.object.map((item) => {
              item.show = true;
              item.deviceDescription = item.devices
                .map((device) => {
                  return device.name;
                })
                .join(", ");
              return item;
            });
            if (payload && payload.completion) {
              payload.completion(data.object);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    updateVportal(context) {
      console.log(
        "context.state.editingVportal :>> ",
        context.state.editingVportal
      );
      let headers = apiUtil.getTokenHeaders("application/json");
      let vportal = pick(context.state.editingVportal, ["name", "devices"]);
      console.log("headers :>> ", headers);
      console.log("vportal :>> ", vportal);

      let config = {
        headers,
      };

      axios
        .patch(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/vportal/${context.state.editingVportal.id}`,
          vportal,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.vportalSaved"
              ),
              color: "success",
            });
            context.dispatch("getVportals");
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: Vuetify.framework.lang.translator(
              "vportal.editForm.failMessage"
            ),
            color: "error",
          });
        });
    },
    getVportalInfo(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/vportal/${payload.id}`,
          {
            headers,
          }
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);

            if (payload && payload.completion) {
              payload.completion(data.object);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    createVportal(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");
      let vportal = pick(payload, ["name", "address", "devices"]);

      console.log("headers :>> ", headers);
      console.log("payload :>> ", payload);
      console.log("vportal :>> ", vportal);

      let config = {
        headers,
      };

      axios
        .post(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/vportal/`,
          vportal,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.vportalAdded"
              ),
              color: "success",
            });
            if (payload && payload.competion) {
              payload.competion(true);
            }

          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
  },
};
