export default {
  pageText: "{0} es",
  noDataText: "Sin datos",
  lang: {
    ru: "Русский",
    en: "English",
    es: "Español",
  },
  addPlaylist: {
    name: "Nombre",
    urlAddress: "Dirección URL .M3U8 o conexión a VPortal",
    forDevices: "Disponible en dispositivos",
    save: "Guardar",
    incorrectUrl: "URL incorrecta",
    onlyM3UAllowed: "Sólo está disponible la descarga de listas de reproducción M3U y m3u8",
    selectAll: "Seleccionar todo",
    playlistAllowOnAll: "La lista de reproducción estará disponible en todos tus dispositivos",
    playlistAllowOnSelected: "La lista de reproducción estará disponible en los dispositivos seleccionados",
    selectOneOrMore: "Seleccione uno o más dispositivos",
    allowOnDevices: "La lista de reproducción estará disponible en los dispositivos seleccionados"
  },
  drawer: {
    player: "Player",
    addPlaylist: "Añadir lista de reproducción",
    playlists: "Listas de reproducción",
    vportals: "VPortals",
    devices: "Equipo",
    profiles: "Perfiles",
    exit: "Salida"
  },
  devices: {
    deleteButton: "Eliminar",
    deleteDialog: {
      title: "Eliminación",
      wantDelete: "Desea desinstalar el dispositivo: {0}",
      cancel: "Cancelación",
      delete: "Eliminar"
    }
  },
  loginForm: {
    title: "Autorización",
    emailField: "Email",
    passwordField: "Contraseña",
    loginButton: "Entrar",
    registrationButton: "Registro",
  },
  playlist: {
    channels: "{0} canales",
    allowedOn: "Disponible en {0} dispositivos",
    details: "Detallado",
    edit: "Cambiar",
    deleteDialog: {
      title: "Eliminación",
      wantDelete: "Quieres eliminar la lista de reproducción: {0}",
      cancel: "Cancelación",
      delete: "Eliminar"
    },
    editForm: {
      title: "Editar una lista de reproducción",
      nameField: "Nombre",
      saveButton: "Guardar",
      cancelButton: "Cancelación",
      failMessage: "Se produjo un error al guardar"
    },
  },
  vportal: {
    key: "Key: {0}",
    address: "URL: {0}",
    allowedOn: "Disponible en {0} dispositivos",
    details: "Detallado",
    edit: "Cambiar",
    deleteDialog: {
      title: "Eliminación",
      wantDelete: "Quieres eliminar la lista de VPortal: {0}",
      cancel: "Cancelación",
      delete: "Eliminar"
    },
    editForm: {
      title: "Editar una lista de VPortal",
      nameField: "Nombre",
      saveButton: "Guardar",
      cancelButton: "Cancelación",
      failMessage: "Se produjo un error al guardar"
    },
  },
  profile: {
    saveSystemDialogSave: "Actualizar sistema",
    saveDialog: "La lista de canales disponibles ha cambiado. Recuerde guardar los cambios.",
    saveDialogSave: "Guardar",
    saveDialogCancel: "Cancelación",
    currentPlaylist: "Lista de reproducción",
    groupFilter: "Filtrar por grupos",
    currentPage: "Página {0} de {1}",
    channels: "Canales",
    deleteDialog: {
      title: "Eliminación",
      wantDelete: "Desea eliminar el perfil: {0}",
      cancel: "Cancelación",
      delete: "Eliminar"
    },
    editForm: {
      title: "Editar perfil",
      nameField: "Nombre",
      saveButton: "Guardar",
      restoreButton: "Restaurar",
      deleteButton: "Eliminar",
      cancelButton: "Cancelación",
      pinFieldHint: "PIN de perfil",
      pinFieldDesc: "Escriba 4 dígitos o deje el campo en blanco",
    },
  },
  registrationForm: {
    title: "Registro",
    emailField: "Email",
    nickField: "Alias",
    loginButton: "Entrar",
    registrationButton: "Registro",
  },
  detail: {
    privacyPolicy: "Política de privacidad",
    terms: "Condiciones de uso",
  },
  formMessages: {
    isRequired: "El campo debe estar lleno",
    mustBeLess: "El campo no debe contener más de {0} caracteres",
    onlyDigits: "Поле может содержать только цифры",
    mustBeEqual: "Поле должно содержать ровно {0} символа",
    incorrectEmail: "Introduzca el correo electrónico correcto",
  },
  emptyView: {
    titleDevice: "La lista de dispositivos está vacía",
    descriptionDevice: "Agregar un nuevo dispositivo a esta lista se realiza automáticamente cuando instala la aplicación en su dispositivo para reproducir videos. Después de la primera autorización exitosa en el dispositivo bajo su cuenta.",
    titleSource: "Lista de reproducción vacía",
    addPlaylist: "Ir a agregar un enlace a una lista de reproducción",
    titleVportal: "La lista de VPortales está vacía",
    descriptionVportal: "Pronto, las aplicaciones en todas las plataformas admitirán el trabajo con servidores VPortal. Si ya tiene los detalles de acceso al portal, puede agregarlos para acceder a películas y programas. Por el momento, el acceso al VPortal solo se implementa en dispositivos Apple.",
    addVportal: "Ir a agregar VPortal",
    routeButtonAdd: "Agregar",
    descriptionSource: "Trabajar con la aplicación supone que Ya tiene un enlace a la lista de reproducción M3U o m3u8. Agrega un enlace a La lista de reproducción que tienes. Después de la primera autorización exitosa en su dispositivo. After the first successful authorization on your device.",
    downloadDescription: "La aplicación está disponible en dispositivos de Google o Apple",
    titleProfile: "La lista de perfiles aún no está disponible",
    descriptionProfile: "Trabajar con la aplicación supone que Ya tiene un enlace a la lista de reproducción M3U o m3u8. Agrega un enlace a La lista de reproducción que tienes. Después de la primera autorización exitosa en su dispositivo. After the first successful authorization on your device.",
  },
  externalView: {
    title: "¿Dónde conseguir una lista de reproducción?",
    description: "Si aún no tiene una lista de reproducción para ver, puedo recomendar el maravilloso Servicio del mismo nombre. Regístrese, obtenga un enlace a una lista de reproducción con miles de canales de TV y disfrute de una excelente calidad de video y sonido.",
    secondDescription: "Para agregar el acceso que tiene al VPortal, escriba la cadena de conexión al portal en el campo de dirección. La cadena de conexión es: portal::[key:1234567890abcde-1234567890abcde]http://server.com/api/v1/",
    addition: "¡Disfruta viendo!",
    buttonLabel: "Adelante para las listas de reproducción",
  },
  playerView: {
    title: "iLook ott player",
    description: "iLook ott player: un Reproductor simple y conveniente para ver listas de reproducción M3U8 está disponible en varias plataformas populares. Puede encontrar fácilmente la aplicación en las tiendas de aplicaciones App Store y Google Play. Más recientemente, ganó la versión web de la aplicación.",
    description_important: "importante!",
    description_attansion: "La aplicación no contiene canales de televisión y sirve exclusivamente para reproducir listas de reproducción que ya tiene.",
    labelToSite: "Ver en el sitio"
  },
  successMessages: {
    profileRestored: "Perfil restaurado",
    successLogin: "¡Bienvenido!",
    successRegistration: "Sus datos de registro se envían al correo electrónico especificado",
    playlistAdded: "Lista de reproducción agregada con éxito",
    playlistSaved: "Cambios guardados en la lista de reproducción",
    playlistDeleted: "Lista de reproducción eliminada",
    vportalAdded: "VPortal agregada con éxito",
    vportalSaved: "Cambios guardados",
    vportalDeleted: "VPortal eliminada",
    deviceUpdated: "Información del dispositivo actualizada",
    deviceDeleted: "Dispositivo eliminado",
    profileAdded: "Perfil añadido correctamente",
    profilesSaved: "Cambios en los perfiles guardados",
    profileSaved: "Perfil guardado"
  },
  failMessages: {
    failProfileAdd: "Error al agregar un perfil",
    failProfileUpdate: "Error al actualizar el perfil",
    failPlaylistUpdate: "Error al actualizar la lista de reproducción",
  }
};
