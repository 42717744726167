<template>
  <v-row>
    <app-drawer></app-drawer>
    <v-col :style="getStyle()">
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-col>
  </v-row>
</template>

<script>
import Drawer from "@/components/AppDrawer";
export default {
  components: {
    appDrawer: Drawer,
  },
  name: "PersonalPage",
  data() {
    return {};
  },
  methods: {
    getStyle() {
      if (!this.$store.state.drawer.isSmallScreen) {
        return "padding-left: 270px";
      } else {
        return "";
      }
    },
  },
};
</script>