<template>
  <v-row justify="center">
    <v-dialog
      v-model="$store.state.device.isShowingDeleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline"> {{$vuetify.lang.translator('devices.deleteDialog.title')}} </v-card-title>
        <v-card-text
          >
          {{$vuetify.lang.translator('devices.deleteDialog.wantDelete', $store.getters.getDeviceName)}}
          </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancel"> {{$vuetify.lang.translator('devices.deleteDialog.cancel')}} </v-btn>
          <v-btn color="green darken-1" text @click="action"> {{$vuetify.lang.translator('devices.deleteDialog.delete')}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DeviceDeleteDialog",
  //   props: {
  //     device: {
  //       type: Object,
  //       required: true,
  //     },
  //   },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    cancel() {
      this.$store.commit("setDeletingDevice", null);
    },
    action() {
      this.$store.dispatch("deleteDevice");
    },
  },
  destroyed() {
    this.$store.commit("setDeletingDevice", null);
  },
};
</script>