<template>
  <v-dialog
    persistent
    max-width="700px"
    v-model="$store.state.vportal.isShowingEditDialog"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            this.$vuetify.lang.translator("vportal.editForm.title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :counter="20"
                  :rules="nameRules"
                  :label="
                    this.$vuetify.lang.translator('vportal.editForm.nameField')
                  "
                  required
                  v-model="$store.state.vportal.editingVportal.name"
                ></v-text-field>

                <v-select
                  :hint="`${$store.state.vportal.editingVportal.devices.name}, ${$store.state.vportal.editingVportal.devices.id}`"
                  v-model="$store.state.vportal.editingVportal.devices"
                  :items="$store.state.device.devices"
                  item-text="name"
                  item-value="id"
                  :label="$vuetify.lang.translator('addPlaylist.forDevices')"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            $store.state.vportal.editingVportal.devices
                              .length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> {{$vuetify.lang.translator('addPlaylist.selectAll')}} </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item disabled>
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon> mdi-television </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content v-if="likesAllFruit">
                        <v-list-item-title>
                          {{$vuetify.lang.translator('addPlaylist.playlistAllowOnAll')}}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content v-else-if="likesSomeFruit">
                        <v-list-item-title>
                          {{$vuetify.lang.translator('addPlaylist.playlistAllowOnSelected')}}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            $store.state.vportal.editingVportal.devices.length
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-content v-else>
                        <v-list-item-title>
                          {{$vuetify.lang.translator('addVportal.selectOneOrMore')}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{$vuetify.lang.translator('addVportal.allowOnDevices')}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">{{
            this.$vuetify.lang.translator("vportal.editForm.cancelButton")
          }}</v-btn>
          <v-btn
            :disabled="!isValidForm"
            color="blue darken-1"
            text
            @click="saveVportalIfCan()"
            >{{
              this.$vuetify.lang.translator("vportal.editForm.saveButton")
            }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      lazy: false,
      valid: false,
      nameRules: [
        (v) => !!v || this.$vuetify.lang.translator("formMessages.isRequired"),
        (v) =>
          (v && v.length <= 30) ||
          this.$vuetify.lang.translator("formMessages.mustBeLess", 20),
      ],
    };
  },
  computed: {
    isValidForm() {
      return this.valid;
    },
    likesAllFruit() {
      return (
        this.$store.state.vportal.editingVportal.devices.length ===
        this.$store.state.device.devices.length
      );
    },
    likesSomeFruit() {
      return (
        this.$store.state.vportal.editingVportal.devices.length > 0 &&
        !this.likesAllFruit
      );
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    cancel() {
      this.$store.commit("setVportalForEdit", null);
    },
    saveVportalIfCan() {
      this.$refs.form.validate();
      if (!this.isValidForm) {
        return;
      }
      this.$store.dispatch("updateVportal");
      this.$store.commit("setVportalForEdit", null);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.$store.state.vportal.editingVportal.devices = [];
        } else {
          this.$store.state.vportal.editingVportal.devices = this.$store.state.device.devices.map((element)=> element.id);
        }
      });
    },
  },
  watch: {},
  destroyed() {
    this.$store.commit("setVportalForEdit", null);
  },
};
</script>


<style scoped>
</style>
