const crypto = require("crypto");

class HashUtil {
  getHash(data, password) {
    let hashFieds = ["email", "time", "app"];

    let dataKeys = Object.keys(data);

    let keysForMakeHash = [];

    dataKeys.forEach((item) => {
      if (hashFieds.includes(item)) {
        keysForMakeHash.push(item);
      }
    });

    let hashString = "";

    keysForMakeHash.sort().forEach((item) => {
      hashString += data[item];
    });

    hashString += password;

    return crypto
      .createHash("sha256")
      .update(hashString)
      .digest("hex");
  }
}

module.exports = new HashUtil();
