import axios from "axios";
import Vuetify from "../plugins/vuetify";
import router from "../router";
import apiUtil from "../util/api_util";

export default {
  state: {},
  getters: {},
  mutations: {
    setToken(state, payload) {
      console.log("payload :>> ", payload);
      if (localStorage.token != null && localStorage.token != "null") {
        router.push({ name: "add_source" });
      } else if (payload.token) {
        localStorage.token = payload.token;
        router.push({ name: "add_source" });
      } else {
        router.push({ name: "login" });
      }
    },
  },
  actions: {
    tryRegistr(context, payload) {
      console.log("payload :>> ", payload);

      localStorage.email = payload.email;

      let headers = apiUtil.getSecHeaders(
        context.getters.getDefWord,
        payload.lang,
        "text/html"
      );

      // headers.token = payload.token;
      // headers.nick = headers.email.split("@")[0];

      console.log("headers :>> ", headers);

      let config = { headers };

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/auth/restore/`,
          config
        )
        .then((response) => response.data)
        .then((data) => {
          console.log("data :>> ", data);

          // if (data && data.error && data.error.code == "dublicate_email") {
          //   context.dispatch("tryRestore", payload);
          //   return;
          // }
          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }
          if (data && data.object) {
            router.push({ name: "login" });
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.successRegistration"
              ),
              color: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    tryLogin(context, payload) {
      localStorage.email = payload.email;

      let headers = apiUtil.getSecHeaders(
        payload.password,
        payload.lang,
        "application/json"
      );

      headers.recaptchaToken = payload.recaptchaToken

      console.log("headers :>> ", headers);

      let config = {
        headers,
      };

      axios
        .post(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/auth/login/`,
          {
            name: "site",
            os: "os",
            manufacturerId: "manufacturerId",
            brand: "brand",
            model: "model"
          },
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }
          if (data && data.object) {
            router.push({ name: "sources" });
            // router.push({ path: '/personal/sources' })

            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator("successMessages.successLogin"),
              color: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },

    // tryRestore(context, payload) {
    //   console.log("payload :>> ", payload);

    //   let headers = apiUtil.getSecHeaders(
    //     context.getters.getDefWord,
    //     payload.lang,
    //     "text/html"
    //   );

    //   console.log("headers :>> ", headers);

    //   let config = {
    //     headers,
    //   };

    //   axios
    //     .get(
    //       `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/auth/restore/`,
    //       {},
    //       config
    //     )
    //     .then((response) => response.data)
    //     .then((data) => {
    //       console.log("data :>> ", data);

    //       if (data && data.error) {
    //         context.commit("showSnackbar", {
    //           text: data.error.description,
    //           color: "error",
    //         });
    //       }
    //       if (data && data.object) {
    //         context.commit("showSnackbar", {
    //           text: Vuetify.framework.lang.translator(
    //             "successMessages.successRegistration"
    //           ),
    //           color: "success",
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       context.commit("showLoginPageIfNeed", error);
    //       context.commit("showSnackbar", {
    //         text: error,
    //         color: "error",
    //       });
    //     });
    // },
    tryLogout(context) {
      let headers = apiUtil.getTokenHeaders("text/html");

      console.log("headers :>> ", headers);

      let config = {
        headers,
      };

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/auth/logout/`,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            console.log(data.error);
          }
          if (data && data.object) {
            console.log("logout");
          }
        })
        .catch((error) => {
          context.commit("showLoginPageIfNeed", error);
          console.log(error);
        });

      localStorage.token = null;
      router.push({ name: "login" });
    },
    getInfo(context) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/auth/info/`,
          {
            headers,
          }
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.object) {
            console.log("object :>> ", data.object);
            context.commit("updateUserId", data.object.id);
            context.commit("updateUserNick", data.object.nick);
            context.commit("updateUserEmail", data.object.email);
            context.commit("updateUserFile", data.object.file);
          }
        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          // router.push({ name: "login" });
        });
    },
  },
};
