import axios from "axios";
import apiUtil from "../util/api_util";
import Vuetify from "../plugins/vuetify";

export default {
  state: {
    devices: [],
    editingDevice: null,
    isShowingDeleteDialog: false,
    deletingDevice: null,
  },
  getters: {
    getDeviceName(state) {
      if (state.deletingDevice != null) {
        return state.deletingDevice.name;
      } else {
        return "";
      }
    },
    isEditable: (state) => (id) => {
      if (state.editingDevice == null) {
        return false;
      } else {
        return state.editingDevice.id == id;
      }
    },
  },
  mutations: {
    setDeletingDevice(state, payload) {
      state.isShowingDeleteDialog = payload != null;
      state.deletingDevice = payload;
    },
    clear(state) {
      state.editingDevice = null;
    },
    restore(state) {
      if (state.editingDevice) {
        var index = state.devices
          .map(function (item) {
            return item.id;
          })
          .indexOf(state.editingDevice.id);
        state.devices[index].name = state.editingDevice.name;
        console.log("state.editingDevice.name :>> ", state.editingDevice.name);
      }
    },
  },
  actions: {
    save(context) {
      console.log("state.editingDevice :>> ", context.state.editingDevice);
      if (context.state.editingDevice) {
        var index = context.state.devices
          .map(function (item) {
            return item.id;
          })
          .indexOf(context.state.editingDevice.id);

        console.log(
          "save :>> ",
          context.state.devices[index].id,
          context.state.devices[index].name
        );

        context.dispatch("updateDevice", {
          id: context.state.devices[index].id,
          name: context.state.devices[index].name,
        });

        context.state.editingDevice = null;
      } else {
        console.log("no devices");
      }
    },
    getDevices(context) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      axios
        .get(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/device/`,
          {
            headers,
          }
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object && Array.isArray(data.object)) {
            console.log("object :>> ", data.object);
            context.state.devices = data.object.map((item) => {
              item.isEdit = false;
              return item;
            });
          }
        })

        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });

        });
    },
    createDevice(context, payload) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);
      console.log("payload :>> ", payload);

      let config = {
        headers,
      };

      axios
        .post(
          `${context.getters.getServerUrl}${context.getters.getAgetAppNameAndPrefixppName}/device/`,
          payload,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            console.log("object :>> ", data.object);
          }

        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    updateDevice(context, payload) {
      console.log("payload :>> ", payload);

      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = { headers };

      axios
        .patch(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/device/any/${payload.id}`,
          { name: payload.name },
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data && data.object) {
            context.commit("showSnackbar", {
              text: Vuetify.framework.lang.translator(
                "successMessages.deviceUpdated"
              ),
              color: "success",
            });
          }

        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });
    },
    deleteDevice(context) {
      let headers = apiUtil.getTokenHeaders("application/json");

      console.log("headers :>> ", headers);

      let config = { headers };

      axios
        .delete(
          `${context.getters.getServerUrl}${context.getters.getAppNameAndPrefix}/device/${context.state.deletingDevice.id}`,
          config
        )
        .then((response) => {
          context.commit("updateToken", response);
          return response.data;
        })
        .then((data) => {
          console.log("data :>> ", data);

          if (data && data.error) {
            context.commit("showSnackbar", {
              text: data.error.description,
              color: "error",
            });
          }

          if (data.object) {
            console.log("OK");
            context.dispatch("getDevices");
            return;
          }

        })
        .catch((error) => {
          console.log(error);
          context.commit("showLoginPageIfNeed", error);
          context.commit("showSnackbar", {
            text: error,
            color: "error",
          });
        });

      context.commit("setDeletingDevice", null);
    },
  },
};
